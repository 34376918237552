:root {
  --BFFLogo-textColor: #000;
  --BFFLogo-borderColor: #000;
}

.BFFLogo-bf {
  background-image: url('../assets/logo/letters-bf.svg' param(--color var(--BFFLogo-textColor)));
}

.BFFLogo-fdotfm {
  background-image: url('../assets/logo/letters-fdotfm.svg' param(--color var(--BFFLogo-textColor)));
}

.BFFLogo-bracelet {
  background-image: url('../assets/logo/bracelet.svg' param(--color var(--BFFLogo-borderColor)));
}
