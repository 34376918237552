:root {
  --BFF10-textColor: #000;
  --BFF10-borderColor: #000;
}

.BFF10-bff {
  background-image: url('../assets/logo10/BFF.svg');
}

.BFF10-bffCandles {
  background-image: url('../assets/logo10/Candles.svg');
}

.BFF10-dotfm {
  background-image: url('../assets/logo10/dotFM.svg');
}

.BFF10-dotFmCandles {
  background-image: url('../assets/logo10/dotFm-candles.svg');
}

.BFF10-bracelet {
  background-image: url('../assets/logo10/Bracelet.svg');
}

/* Flames */

.BFF10-flameBa {
  background-image: url('../assets/logo10/ba.svg');
}
.BFF10-flameBb {
  background-image: url('../assets/logo10/bb.svg');
}
.BFF10-flameBc {
  background-image: url('../assets/logo10/bc.svg');
}
.BFF10-flameF1a {
  background-image: url('../assets/logo10/f1a.svg');
}
.BFF10-flameF1b {
  background-image: url('../assets/logo10/f1b.svg');
}
.BFF10-flameF1c {
  background-image: url('../assets/logo10/f1c.svg');
}
.BFF10-flameF2a {
  background-image: url('../assets/logo10/f2a.svg');
}
.BFF10-flameF2b {
  background-image: url('../assets/logo10/f2b.svg');
}
.BFF10-flameF2c {
  background-image: url('../assets/logo10/f2c.svg');
}
.BFF10-flameF3a {
  background-image: url('../assets/logo10/f3a.svg');
}
